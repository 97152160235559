<template>
  <div class="container-page">
    <div class="container-support-text">
      <div class="body-1">
        This tool is free to use, but it does cost me money and time.
      </div>
      <div class="body-1">~ All support is appreciated ~</div>
      <div class="body-1">For contact: cuttingtemplates@gmail.com</div>
    </div>
  </div>
  <iframe id="kofiframe" src="https://ko-fi.com/cuttingtemplates/?hidefeed=true&widget=true&embed=true&preview=true"
    class="kofi-iframe" title="cuttingtemplates"></iframe>
</template>

<script>
export default {
  name: "SupportPage",
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.kofi-iframe {
  border: none;
  width: 100%;
  height: 700px;
  padding: 0;
}

.community-block {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 20px;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  border-radius: 20px;
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.community-block:hover {
  background-color: var(--secondary-bg-color);
}

.container-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 2em;
  gap: 2em;
  width: 100%;
  background: var(--main-bg-color);
  box-sizing: border-box;
  border-radius: 20px;
  border-bottom: 2px solid var(--secondary-bg-color);
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.container-support-text {
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: 30px;
}

.container-support-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

@media (max-width: 960px) {
  .container-support-icons {
    padding: 1em;
    flex-direction: column;
  }

  .kofi-iframe {
    width: 100%;
  }
}

.container-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 40px;
}

.card {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 55px;
}

.card img {
  width: 40px;
  height: 40px;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16;
  line-height: 20px;
  color: var(--main-font-color);
}
</style>
